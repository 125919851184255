.cardContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: grey;
    padding: 8px 8px 36px 8px;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.mobileContainer {
    background-color: black;
    border-radius: 5px;
    padding: 16px;
    width: 480px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.titleContainer {
    flex: 1;
    width: 90%
}

.mobileContent {
    width: 100%;
}

.imageWrapper {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
}

.answersWrapper {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.twoImageContainer {
    flex: 1;
    height: 220px;
    position: relative;
    border: 1px solid white;
    margin: 5px 1px;
    max-width: 50%;
}

.imageContainer {
    flex: 1;
    height: 220px;
    position: relative;
    border: 1px solid white;
    margin: 5px 1px;
}

.iconContainer {
    position: relative;
    border: 1px solid white;
    height: 80px;
    width: 60px;
}

.emptyImageContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageButton {
    position: absolute;
    top: 16px;
    right: 16px
}

.iconButton {
    position: absolute;
    top: 0px;
    right: 0px
}

.backgroundImage {
    height: 220px;
    width: 100%;
    object-fit: cover;
}

.iconImage {
    height: 80px;
    width: 60px;
    object-fit: cover;
}

.headerInputField {
    height: 10px;
    font-family: Gotham Pro;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 5;
}

.editorContainer {
    padding: 0px 0px 0px 4px;
}