.cardContent {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	background-color: grey;
	padding: 8px 8px 36px 8px;
}
.mobileContainer {
	background-color: black;
	border-radius: 5px;
	width: 480px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.mobileContent {
	margin-top: -64px;
	padding: 0 16px 16px 16px; 
	width: 100%;
	z-index: 20;
}
.mobileLayout {
	width: 100%;
	background-color: white;
	border-radius: 4px;
}
.imageContainer{
	position: relative;
	width: 100%;
	height: 320px;
}
.imageButton{
	position: absolute;
	top: 16px;
	right: 16px
}
.backgroundImage {
	width: 480px;
	height: 320px;
	object-fit: cover;
}
.headerInputField {
	font-family: Gotham Pro;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	font-weight: bold;
	color: #000000;
	padding: 16px;
}
.editorContainer {
	padding: 0px 0px 0px 4px;
}