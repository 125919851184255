.dock {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
}
.dockBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 8px;
}
.dockBodyText {
	font-family:-apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 14px;
	color: white;
	line-height: 20px;
}
.dockBodySubText {
	font-family:-apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 12px;
	color: rgba(255, 255, 255, 0.65);
	line-height: 16px;
}
