.thText {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.thContainer {
	display: 'flex'; 
	flex-direction: 'column'; 
	justify-content: 'space-between';
}
