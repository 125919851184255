.titleContainer{ 
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex: 1 0 100%;
}
.searchContainer {	
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.searchRightContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}