.image {
    object-fit: cover;
    width: 230px;
    height: 220px;
    border: 1px solid grey;
}

.question {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}