.cardContent {
    margin-top: 50px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: grey;
    padding: 8px 8px 36px 8px;
}

.mobileContainer {
    background-color: black;
    border-radius: 5px;
    padding: 16px;
    width: 480px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.headerInputField {
    height: 100px;
}