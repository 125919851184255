.mediaContainer {
	width: 272px;
	height: 181px;
	float: right;
	margin-left: 16px;
}
.itemContainer {

}
.image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.videoImage {
	object-fit: contain;
	background-color: black;
	width: 100%;
	height: 100%;
}
.contentContainer {
	height: 180px;
}
.description{
	display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
.cardMetaContainer {
	height: 70px;
}