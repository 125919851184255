.container {
	height: 40px;
	display: flex;
	align-items: flex-end;
}
.version {
    color: rgba(255, 255, 255, 0.65);
    padding: 8px;
    font-size: 12px;
    line-height: 12px;
}