.editor {
	position: relative;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	width: 100%;
	padding: 8px 12px;
}
.inputContainer {
	width: 280px;
}