body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Styling Antd layout */
:global(.ant-layout) {
  max-width: 100vw;
}

/* Styling Antd Table */
:global(.ant-table-column-sorters) {
  display: flex;
}
:global(div.ant-table-column-sorters > span:nth-child(1)) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Styling article editor */
:global(.megadraft-editor .megadraft-block ) {
  padding-bottom: 2px !important;
}
:global(.megadraft-editor .megadraft-block .public-DraftStyleDefault-ul li) {
  list-style: none;
}
:global(.megadraft-editor .megadraft-block .public-DraftStyleDefault-ul li::before) {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #C4B36A; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -28px;
  font-size: 20px;
  float: left;
}

:global(.megadraft-editor .megadraft-block blockquote:before) {
  content: "\"";
  color: #E8CF62;
  font-family: Merriweather;
  font-size: 48px;
  line-height: 36px;
  padding: 0;
  margin: 0;
}
:global(.megadraft-editor .megadraft-block blockquote) {
  font-family: Merriweather;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  border: 0;
}