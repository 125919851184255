.listContainer {
	max-height: 50vh;
	min-height: 50vh;
    overflow-y: scroll;
}
.moreButtonContainer {
	text-align: center;
	margin-top: 12px;
	height: 32px;
	line-height: 32px;
}
