.itemContainer {
    height: 329px;
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    flex-grow: 1;
	flex-shrink: 1;
	border: 1px solid #ececec;
}
.itemContainer:not(:last-child) {
	border-right: 1px solid transparent;
}
.image {
	height: 210px;
	width: 100%;
	margin-bottom: 8px;
	object-fit: cover;
}
.brandTitle {
	font-family: Gotham-Pro sans-serif;
	font-size: 14px;
	line-height: 14px;
	color: #000000;
	padding: 8px 22px;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
}
.itemName {

	font-family: Merriweather sans-serif;
	font-size: 12px;
	line-height: 14px;
	padding: 8px 22px;
	text-align: center;
	color: #000000;
}
.pricePurchased {
	font-family: Gotham-Pro sans-serif;
	font-size: 14px;
	line-height: 16px;
	padding: 16px 22px;
	text-align: center;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #000000;
	font-weight: bold;
}
