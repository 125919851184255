.card {
	width: 240px;
	height: 340px;
	overflow: hidden;
}
.image {
	height: 200px;
	object-fit: cover;
}
.description {
	overflow-y: scroll;
	max-height: 40px;
}
